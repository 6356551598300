(function () {
  $(function() {
    var $accordion = $(".js-voiceAccordion");

    $accordion.each(function() {
      var $this = $(this);

      // もし.c-voice__messageが2行以下やったら、アコーディオン機能を無効にする
      if ($this.outerHeight() <= parseInt($this.css('line-height')) * 2) {
        // 3行以下やからアコーディオンのクラスをつけん
        $this.removeClass('js-voiceAccordion');
      } else {
        // 3行以上やからクリックイベントをバインドする
        $this.on("click", function(){
          $this.toggleClass("is-active");
        });
      }
    });
  });
})();
